import { defineComponent, onMounted, reactive, ref } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

import Card from '@/components/Card';

import { Skeleton } from '@/components/UI';

import { getArea } from '@/api';
import { router } from '@/config';
import store from '@/store';

export default defineComponent({
	components: { Card, Skeleton },
	setup(props: any, { root }: any) {
		const { $route, $message } = root;
		const isLoading: Ref<boolean> = ref(true);
		const isError: Ref<boolean> = ref(false);
		const isShowAll: Ref<boolean> = ref(false);
		const allData: Ref<any> = ref([]);
		const category: Ref<any> = ref({
			onlyid: '',
			title: '未知分类',
			description: '暂无简介',
			image: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
		});
		const countArticle: Ref<number> = ref(0); //文章数量
		onMounted(() => {
			getArea('BLOG_ARTICLE_CATEGORY')
				.then((data: any) => {
					let find = data.find((item: any) => item.onlyid === $route.params.onlyid);
					//如果是显示全部分类
					if ($route.params.onlyid === undefined) {
						isShowAll.value = true;
						allData.value = data;
						//console.log(allData.value);
						return;
					}
					//如果是分类明细页
					if (!find) {
						find = data
							.find((item: any) => {
								if (item.children && Array.isArray(item.children) && item.children.length > 0) {
									return item.children?.find((item1: any) => item1.onlyid === $route.params.onlyid);
								} else {
									return undefined;
								}
							})
							?.children?.find((item1: any) => item1.onlyid === $route.params.onlyid);
					}
					if (!find) {
						throw '无法获取该分类:' + $route.params.onlyid;
					}
					category.value = Object.assign(category.value, find) || category.value;
					category.value.title &&
						store.commit('changeMetaInfo', {
							title: category.value.title + ' - ' + store.state.metaInfo.title,
						});
				})
				.catch(error => {
					isError.value = true;
					$message.error(error);
				})
				.finally(() => {
					isLoading.value = false;
				});
		});
		return {
			category,
			isShowAll,
			isLoading,
			isError,
			allData,
			countArticle,
		};
	},
});
